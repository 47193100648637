<template lang="pug">
  div.container(ref="section")
    .footer
      .footer__text Листая дальше, вы перейдете на страницу &#32
        a.footer__link(
          @click="() => openLink(true)"
          :href="link"
          :name="linkText"
        ) {{ linkText }}
      .footer__pitch {{ pitch }}
      p.observer-tag.observer-tag_footer(
        ref="footerObserverTag"
      )

    .content
      .content__banner-overlay(ref="overlay")
      .content__container
        .content__subscribe(ref="subscribe")
          .content__scroll-to-site
            .content__scroll-to-site-container(ref="absoluteContainer")
              .content__navigator
                .content__navigator_overlay
                p.observer-tag.observer-tag_navigator(ref="navigatorObserverTag")
              .content__banner(
                ref="banner"
                @click="() => openLink(true)"
              )
                .content__image(:style="{'background-image': `url(${imageUrl})`}")
</template>

<script lang="ts">
  import { NOOP, timeout, createObserver } from '~/utils';
  import { jumperProps as props } from '~/components/jumper/props';

  const DELAY_BEFORE_SHOW_JUMPER_CONTENT = 1000;

  export default defineNuxtComponent({
    name: 'JumperMobile',

    props,

    data: (): {
      unobserveFooter: () => void;
      unobserveNavigator: () => void;
    } => ({
      unobserveFooter: NOOP,
      unobserveNavigator: NOOP,
    }),

    mounted() {
      this.addObserverInsideFooter();

      window.addEventListener('scroll', this.handleScroll);
    },

    destroyed() {
      this.unobserveFooter();
      this.unobserveNavigator();
    },

    methods: {
      addObserverInsideFooter(): void {
        const observerTag = this.$refs.footerObserverTag;
        if (!(observerTag instanceof HTMLElement)) {
          return;
        }
        const observer = createObserver(this.callbackFooter);
        observer.observe(observerTag);

        this.unobserveFooter = () => observer.unobserve(observerTag);
      },

      handleScroll(): void {
        const sectionRef = this.$refs.section as HTMLElement | undefined;
        if (!sectionRef) {
          return;
        }

        const sectionOffsetTop = sectionRef.getBoundingClientRect().top;
        if (sectionOffsetTop >= window.innerHeight / 1.5) {
          this.setOverlayOpacity(0);
          return undefined;
        }

        const scale = 1 - sectionOffsetTop / window.innerHeight;

        this.setOverlayOpacity(scale - 0.1);
      },

      async callbackFooter([
        { intersectionRatio },
      ]: Array<IntersectionObserverEntry>): Promise<undefined> {
        const isScrollingToBottom: boolean = Boolean(intersectionRatio);
        if (!isScrollingToBottom) {
          return undefined;
        }

        const success = await this.showContent();
        if (!success) {
          return undefined;
        }

        this.unobserveFooter();

        this.addObserverInsideNavigator();
      },

      async showContent(): Promise<boolean> {
        const { absoluteContainer, subscribe, banner } = this.$refs;
        if (
          !(absoluteContainer instanceof HTMLElement) ||
          !(subscribe instanceof HTMLElement) ||
          !(banner instanceof HTMLElement)
        ) {
          return false;
        }

        await timeout(DELAY_BEFORE_SHOW_JUMPER_CONTENT);

        subscribe.style.height = 'auto';
        absoluteContainer.style.height = '100vh';
        banner.style.height = '100vh';

        return true;
      },

      addObserverInsideNavigator(): void {
        const { navigatorObserverTag } = this.$refs;
        if (!(navigatorObserverTag instanceof HTMLElement)) {
          return undefined;
        }

        const observer = createObserver(this.callbackNavigator);
        observer.observe(navigatorObserverTag);
        this.unobserveNavigator = () => observer.unobserve(navigatorObserverTag);
      },

      callbackNavigator([{ intersectionRatio }]: Array<IntersectionObserverEntry>) {
        const isScrollingToBottom = !!intersectionRatio;
        if (isScrollingToBottom) {
          this.openLink();
          this.unobserveNavigator();
        }
      },

      setOverlayOpacity(opacity: number): void {
        const { overlay } = this.$refs;
        if (overlay instanceof HTMLElement) {
          overlay.style.opacity = `${opacity}`;
          overlay.style.pointerEvents = opacity ? 'all' : 'none';
        }
      },

      // @param {boolean} isFromClick - Пришло ли событие с клика пользователя или ссылка открыта
      // из-за скролла
      openLink(isFromClick = false): void {
        this.$emit('open-link', isFromClick);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @use './_variables.scss' as jumperVars;

  .container {
    width: 100%;
    z-index: 17;
  }

  .observer-tag {
    position: absolute;
    left: 50%;

    width: 1px;
    height: 1px;

    opacity: 0;

    &_footer {
      bottom: 10%;
    }

    &_navigator {
      bottom: 30%;
    }
  }

  .footer {
    position: relative;
    top: 0;

    width: 100%;
    height: auto;
    padding: 32px 16px;

    background-color: #ffffff;
    text-align: center;
    z-index: 2;
  }

  .footer__text {
    position: relative;
    top: 0;

    display: -webkit-box;
    display: flex;
    max-height: 2.5em;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    flex-shrink: 0;

    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .footer__pitch {
    margin-top: 8px;

    font-family: $secondFont;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
  }

  .footer__link {
    display: contents;
  }

  .content {
    position: relative;

    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .content__container {
    max-width: 600px;
    flex: none;
    -webkit-box-flex: 0;
    box-sizing: border-box;

    word-wrap: break-word;
  }

  .content__subscribe {
    height: 0;
  }

  .content__scroll-to-site {
    height: 100vh;
    width: 100%;
  }

  .content__scroll-to-site-container {
    position: absolute;
    left: 0;

    height: 0;
    width: 100%;
    display: -webkit-box;
    display: flex;

    overflow: hidden;
  }

  .content__navigator {
    width: 100%;
    height: 100%;
  }

  .content__navigator_overlay {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
    background-color: jumperVars.$overlayBackgroundColor;
    -webkit-transition: opacity 1s ease-in;
    opacity: 0;
    transition: opacity 1s ease-in;
    z-index: 3;
  }

  .content__banner {
    position: fixed;
    bottom: 0;
    left: 0;

    display: -webkit-box;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: height;

    z-index: -1;
  }

  .content__banner-overlay {
    position: absolute;
    top: -100%;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
    pointer-events: none;
    z-index: 1;
    will-change: opacity;
  }

  .content__image {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    max-width: 767px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    pointer-events: none;

    @include tablet() {
      max-width: 1220px;
    }
  }
</style>
