<template>
  <a
    v-if="link && image"
    class="partner-banner__link"
    :href="link"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Перейти на сайт партнера статьи"
  >
    <img
      class="partner-banner__image"
      :src="image"
      alt="Баннер партнера статьи"
    />
  </a>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  export type PartnerBannerProps = {
    image?: string | null;
    link?: string | null;
  };

  export default defineNuxtComponent({
    name: 'PartnerBanner',

    props: {
      image: {
        type: String as PropType<PartnerBannerProps['image']>,
        default: null,
      },
      link: {
        type: String as PropType<PartnerBannerProps['link']>,
        default: null,
      },
    },
  });
</script>

<style lang="scss" scoped>
  .partner-banner__link {
    display: block;
    margin-bottom: 24px;
    width: 300px;
    height: 600px;
    background: white;
  }
</style>
