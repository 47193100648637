<template lang="pug">
.the-tv(v-if='firstArticle')
  component.the-tv__observer.the-tv__observer_large(
    @appear.once='onAppear(firstArticle)',
    :is='isTrackTheAppearance(firstArticle) ? "IntersectionObserver" : "div"'
  )
    ArticleCardLarge(
      v-bind='useArticleProps(firstArticle)',
      :loadingViews='getLoadingViews(firstArticle.id)',
      @clicked-on-comments='sendEvent',
      @click-on-restore-button='updateViewsCount(firstArticle.id)',
      @favorite-delete='(favorite) => onFavoriteDelete(favorite, firstArticle)',
      @favorite-create-folder-and-add='(nameFolder) => onFavoriteCreateFolderAndAdd(nameFolder, firstArticle)',
      @favorite-create-folder-and-transfer='(nameFolder, favorite) => onFavoriteCreateFolderAndTransfer(nameFolder, favorite, firstArticle)',
      @favorite-transfer-to-folder='(favorite, folder) => onFavoriteTransferToFolder(favorite, folder, firstArticle)',
      @favorite-add-to-folder='(folder) => onFavoriteAddToFolder(folder, firstArticle)',
      @favorite-deferred='onFavoriteDeferred',
      @click-on-article-card='onClickArticleCardLarge',
      @clicked-on-author='onClickAuthor',
      @clicked-on-category='onClickCategory',
      img-loading='eager'
    )
  component.the-tv__observer.the-tv__observer_middle(
    v-for='(article, index) of otherArticles',
    :key='article.id',
    @appear.once='onAppear(article)',
    :is='isTrackTheAppearance(article) ? "IntersectionObserver" : "div"'
  )
    ArticleCardMiddle.the-tv__article-card_middle(
      v-bind='useArticleProps(article)',
      :loadingViews='getLoadingViews(article.id)',
      @clicked-on-comments='sendEvent',
      @click-on-restore-button='updateViewsCount(article.id)',
      @favorite-delete='(favorite) => onFavoriteDelete(favorite, article)',
      @favorite-create-folder-and-add='(nameFolder) => onFavoriteCreateFolderAndAdd(nameFolder, article)',
      @favorite-create-folder-and-transfer='(nameFolder, favorite) => onFavoriteCreateFolderAndTransfer(nameFolder, favorite, article)',
      @favorite-transfer-to-folder='(favorite, folder) => onFavoriteTransferToFolder(favorite, folder, article)',
      @favorite-add-to-folder='(folder) => onFavoriteAddToFolder(folder, article)',
      @favorite-deferred='onFavoriteDeferred',
      @clicked-on-author='onClickAuthor',
      @clicked-on-category='onClickCategory',
      img-loading='auto'
    )
</template>

<script lang="ts">
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
  import ArticleCardMiddle from '@/components/lh-ui/ArticleCard/ArticleCardMiddle/ArticleCardMiddle.vue';
  import ArticleCardLarge from '@/components/lh-ui/ArticleCard/ArticleCardLarge/ArticleCardLarge.vue';
  import { getFullUrl } from '~/utils';
  import { useArticleProps } from '~/composables/useArticleProps';
  import { usePageStore } from '~/store/pagesData';
  import { useFavorite } from '@/composables/useFavoriteMethods';
  import { useUpdatingViewsCount } from '@/composables/useUpdatingViewsCount';

  export default defineNuxtComponent({
    name: 'TheTvContainer',

    data() {
      return {
        articlesWithLoadedPixels: [] as number[],
      };
    },

    components: {
      ArticleCardMiddle,
      ArticleCardLarge,
      IntersectionObserver,
    },

    setup() {
      const {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
      } = useFavorite();
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },

    computed: {
      articles(): Array<IArticle> {
        return usePageStore().currentArticles.slice(0, 5);
      },
      firstArticle(): IArticle {
        return this.articles[0];
      },
      otherArticles(): Array<IArticle> {
        return this.articles.slice(1, 5);
      },
    },
    methods: {
      useArticleProps,

      sendEvent(slug: string) {
        this.$sendYandexMetrika({
          level1: 'Комментарии_переход к комментариям',
          level4: getFullUrl(slug),
          level6: 'главная',
        });
      },
      onAppear(article: IArticle): void {
        const testPixel = article?.testPixel;
        const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
        if (testPixel?.length && isNotLoadedPixel) {
          testPixel.forEach((url) =>
            $fetch(url, { credentials: 'include' }).catch((error) => {
              this.$logger(
                'Error in sending text pixels for ' + article + ' in TheTvContainer.vue',
                error,
              );
            }),
          );
          this.articlesWithLoadedPixels.push(article.id);
        }
      },
      isTrackTheAppearance(article: IArticle): boolean {
        const hasTestPixel = Boolean(article?.testPixel);
        return hasTestPixel;
      },
      onClickArticleCardLarge(): void {
        const { teaserUrl } = this.firstArticle;
        const url = teaserUrl || this.firstArticle.url;

        this.$sendYandexMetrika({
          level1: 'Выбор материала_Виджет большая карточка',
          level4: getFullUrl(url),
        });
      },
      onClickAuthor(): void {},
      onClickCategory(link: string, titleCategory: string, _title: string): void {
        this.$sendYandexMetrika({
          level1: 'Выбор рубрики',
          level4: getFullUrl(link),
          level5: titleCategory,
          level6: 'unknown',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .the-tv {
    display: flex;
    flex-direction: column;
    width: calc(100% + 2px);
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include big-tablet {
      width: 832px;
    }
    @include desktop {
      width: 1248px;
    }

    &__article-card {
      &_middle {
        @include tablet {
          width: 100%;
        }
      }
    }

    // Стили ниже для того чтобы рамки между карточками были 1px
    // и рамки при ховере у соседних карточек пересекались.
    // Такие макеты ¯\_(ツ)_/¯
    &__observer {
      &:nth-child(n + 3) {
        // всем элементам, начиная с 3го для того чтобы рамка между элементами была 1px
        margin-top: -1px;
      }
      @include tablet {
        &:nth-child(n + 3) {
          margin-top: 0;
        }

        &:nth-child(n + 2) {
          // всем элементам, начиная с 2го для того чтобы рамка между элементами была 1px
          margin-top: -1px;
          width: calc(50% + 0.5px);
        }

        &:nth-child(3),
        &:nth-child(5) {
          margin-left: -1px;
        }
      }
      @include desktop {
        &:nth-child(n + 2) {
          width: 417px;
          margin-top: 0;
          margin-left: -1px;
        }

        &:nth-child(n + 3) {
          margin-top: -1px;
        }

        &:nth-child(2) {
          width: 417px;
        }

        &:nth-child(3) {
          margin-left: 0;
          width: 416px;
        }
      }
      // для того чтобы при ховере активный элемент был выше соседних
      @include hover {
        z-index: 3;
      }
    }
  }
</style>
