<template lang="pug">
  picture
    source.image(
      :srcset="mobileUrl"
      :media="media"
    )
    img.image(
      :src="desktopUrl"
      :alt="alt"
    )
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';

  export default defineNuxtComponent({
    name: 'BasePicture',

    props: {
      mobileUrl: {
        type: String,
        required: true,
      } as PropOptions<string>,

      desktopUrl: {
        type: String,
        required: true,
      } as PropOptions<string>,

      alt: {
        type: String,
        required: true,
      } as PropOptions<string>,

      media: {
        type: String,
        required: true,
      } as PropOptions<string>,
    },
  });
</script>
