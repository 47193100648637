<template>
  <div
    :style="`
      --main-color: #ed5e42;
      --main-color-2: #2962f9;
      --preview-header-color: #a0270f;
      --hover-color: #d44b3f;
      --hover-color-2: #264eb1;
      --bg-color-substrate: #fbf8f8;
      --bg-color-substrate-2: #f3f5fc;
      --bg-color-substrate-3: #f3f5fc;
    `"
  >
    <PromoContainer
      v-if="isPromoArticle"
      class="content-container__for-jumper-desktop"
    >
      <slot />
    </PromoContainer>

    <main
      v-else
      :class="pageClasses"
      class="page"
    >
      <!-- зона виджетов "баннер над меню" -->
      <div class="page__widgets-above-header">
        <WidgetsRendererContainer
          :widgets="widgetsAboveHeader"
          widget-class-name="page__widget-above-header"
        />
      </div>

      <!-- шапка  -->
      <TopHeaderContainer />

      <!-- Партнерская полоса или меню (разные в мобильной и десктопной версии) -->
      <template v-if="!isAdvertisingPost">
        <PrimaryMenu
          :primary-nav-menu-items="primaryNavMenuItems"
          type="secondary"
          location="mobile"
        />
        <NavMenuBelowHeaderContainer />
      </template>

      <!-- брендирование рендерится внутрь этого контейнера -->
      <ContentWrapperForBranding />

      <!-- зона виджетов "Баннер под меню" -->
      <div
        v-if="widgetsBelowHeader.length && !isArticleTypePage"
        class="widgets-below-header"
      >
        <WidgetsRendererContainer
          :widgets="widgetsBelowHeader"
          widget-class-name="widget-below-header"
        />
      </div>

      <!-- Контент -->
      <!-- Должен находиться в коде раньше других элементов. Важно для SEO LH-1368 -->
      <!-- Для отображения элементов выше этого блока используется класс .order-minus-1 -->
      <div
        :class="leftSidebarAndOtherContent"
        class="left-sidebar-and-other-content content-container__for-jumper-desktop"
      >
        <slot name="above-content" />
        <div class="other-content">
          <!-- Блок "Телевизор" на главной -->
          <TheTvContainer
            v-if="isHomePage"
            id="main"
            class="tv-container"
          />

          <section class="other-content__main-and-right-sidebar">
            <!-- Pages component -->
            <div
              id="main"
              class="other-content__main other-content-container__for-left-sidebar-inside-article"
            >
              <slot />
            </div>

            <!-- зона виджетов "Сайдбар" -->
            <aside
              v-if="sidebarWidgets.length && isRightSidebarVisible"
              class="other-content__right-sidebar other-content__sidebar-widgets"
            >
              <!-- .other-content__sidebar-widget используется для фиксации последнего элемента в джампере -->
              <!-- в сафари (см JumperDesktop) -->
              <WidgetsRendererContainer
                :widgets="sidebarWidgets"
                :root-element-style="{ height: '100%' }"
                :in-sidebar="true"
                widget-class-name="other-content__sidebar-widget"
              >
                <!-- Баннер в партнерских статьях -->
                <template #partner-banner>
                  <PartnerBannerContainer v-if="isAdvertisingPost" />
                </template>
              </WidgetsRendererContainer>
            </aside>
          </section>
        </div>
      </div>

      <!-- навигационное меню для мобильной версии -->
      <NavMenuContainer class="main" />

      <!-- зона виджетов "в посте подвал" -->
      <!-- эта зона блочится адблоком (поэтому в классе 1, если адблок добавит 1 в список запрещенных, заменить на 2 и т.д) -->
      <WidgetsRendererContainer
        v-if="isFooterWidgetVisible || isAdsException"
        class="footer-widgets__container-1"
        :widgets="footerWidgets"
      />

      <!-- Footer -->
      <TheFooterContainerAmp v-if="isShowFooter" />
      <!-- Джампер -->
      <JumperContainer v-if="isJumperPost" />
    </main>
  </div>
</template>

<script lang="ts">
  import type { MetaObject } from 'nuxt/schema';
  import { getISODateString } from '@devhacker/shared/utils/date';
  import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
  import focusVisibleInstance from '~/utils/focus-visible';

  import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
  import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
  import PrimaryMenu from '~/components/PrimaryMenu.vue';
  import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
  import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer.vue';
  import TheTvContainer from '~/containers/TheTvContainer/TheTvContainer.vue';
  import ContentWrapperForBranding from '~/components/ContentWrapperForBranding.vue';

  import { useMenusStore } from '~/store/menus';
  import {
    isHomePage,
    isArticlePage,
    isPreviewPage,
    isHealthPath,
    isHealthMain,
  } from '~/utils/router';
  import {
    percentageInit,
    viewedContentPercent,
    getContentObject,
    viewedPagePercent,
    getPageObject,
    type PercentageObject,
  } from '~/utils/PercentagePageElement';
  import { YA_METRIKA_ID } from '~/constants/config';
  import { ADS_EXCEPTIONS } from '~/constants/ads-labels';
  import PromoContainer from '~/containers/PromoContainer.vue';
  import JumperContainer from '~/containers/JumperContainer/JumperContainer.vue';
  import TheFooterContainerAmp from '~/containers/TheFooterContainer.amp.vue';
  import { usePageStore } from '~/store/pagesData';
  import { useWidgetsStore } from '~/store/widgets';
  import { useHealthStore } from '~/store/health';
  import PartnerBannerContainer from '~/containers/PartnerBannerContainer.vue';
  import { useHeadWithSeo } from '~/composables/useHeadWithSeo';

  const ERROR_TEMPLATE_CLASS = 'error-template-two-column';

  export default defineNuxtComponent({
    name: 'DefaultLayout',

    components: {
      PromoContainer,
      NavMenuContainer,
      TopHeaderContainer,
      PrimaryMenu,
      WidgetsRendererContainer,
      JumperContainer,
      TheFooterContainerAmp,
      PartnerBannerContainer,
      NavMenuBelowHeaderContainer,
      TheTvContainer,
      ContentWrapperForBranding,
    },

    data: () => ({
      isRerender: false,
      metaSingleArticle: [
        {
          hid: 'lh_author',
          name: 'lh_author',
          content: 'null' as string,
        },
        {
          hid: 'lh_category',
          name: 'lh_category',
          content: 'null' as string,
        },
        {
          hid: 'lh_stream',
          name: 'lh_stream',
          content: 'null' as string,
        },
        {
          hid: 'lh_tag',
          name: 'lh_tag',
          content: 'null' as string,
        },
        {
          hid: 'lh_partner',
          name: 'lh_partner',
          content: 'null' as string,
        },
        {
          hid: 'lh_published_time',
          name: 'lh_published_time',
          content: 'null' as string,
        },
        {
          hid: 'lh_first_published_time',
          name: 'lh_first_published_time',
          content: 'null' as string,
        },
      ],
    }),

    setup() {
      const routeName = useRoute().name;

      // Превью закрыты от роботов, остальное сео не нужно
      if (isPreviewPage(routeName)) {
        return {
          meta: [
            {
              hid: 'robots',
              name: 'robots',
              content: 'noindex,nofollow',
            },
          ],
        };
      }

      useHeadWithSeo();
    },

    computed: {
      // Widgets
      widgetsAboveHeader(): TWidget[] {
        return useWidgetsStore().currentAboveHeaderWidgets;
      },
      sidebarWidgets(): TWidget[] {
        return useWidgetsStore().currentSidebarWidgets;
      },
      belowCommentsWidgets(): TWidget[] {
        return useWidgetsStore().currentBelowCommentsWidgets;
      },
      footerWidgets(): TWidget[] {
        return useWidgetsStore().currentFooterWidgets;
      },
      leftSidebarWidgets(): TWidget[] {
        return useWidgetsStore().currentLeftSidebarWidgets;
      },
      widgetsBelowHeader(): TWidget[] {
        return useWidgetsStore().currentBelowHeaderWidgets;
      },
      isBelowCommentsWidgetVisible(): boolean {
        const {
          isSingleArticle,
          isPromoArticle,
          isAdvertisingPost,
          isErrorPage,
          isArticleTypePage,
          isJumperPost,
        } = this;
        return (
          isSingleArticle &&
          !isAdvertisingPost &&
          !isPromoArticle &&
          !isErrorPage &&
          !isArticleTypePage &&
          !isJumperPost
        );
      },
      isFooterWidgetVisible(): boolean {
        const {
          isSingleArticle,
          isPromoArticle,
          isAdvertisingPost,
          isErrorPage,
          isArticleTypePage,
          isJumperPost,
        } = this;
        return (
          isSingleArticle &&
          !isAdvertisingPost &&
          !isPromoArticle &&
          !isErrorPage &&
          !isArticleTypePage &&
          !isJumperPost
        );
      },
      // Widgets END

      isArticleTypePost(): boolean {
        return usePageStore().isArticleTypePost;
      },

      leftSidebarAndOtherContent(): string | null {
        return this.isRerender ? 'left-sidebar-and-other-content--rerender' : null;
      },
      primaryNavMenuItems(): MenuItem[] {
        return useMenusStore().menus.projects?.items ?? [];
      },
      isHealthPath(): boolean {
        return isHealthPath(useRoute().path);
      },
      pageClasses(): string[] {
        const routeName = useRoute().name?.toString();
        const {
          isSingleArticle,
          isJumperPost,
          isErrorPage,
          isHealthAboutProjectPage,
          isHealthExpertsPage,
        } = this;

        if (isErrorPage) {
          return [ERROR_TEMPLATE_CLASS];
        }

        const template = this.article
          ? this.article?.template
          : isHealthAboutProjectPage || isHealthExpertsPage
            ? 'one-column'
            : 'none';
        const classes = [];

        classes.push(`route-${routeName ?? ''}`);

        if (isSingleArticle || isHealthAboutProjectPage || isHealthExpertsPage) {
          classes.push(`template-${template}`);
        } else {
          classes.push('route-archive');
        }

        isJumperPost && classes.push('jumper-post');
        this.isArticleTypePage && classes.push('type-page');

        return classes;
      },
      article(): ISingleArticle | null {
        return usePageStore().currentArticle;
      },
      isHomePage(): boolean {
        return isHomePage(useRoute().name);
      },
      isSingleArticle(): boolean {
        const routeName = useRoute().name;
        // todo: после того как будет готово API для определения
        // страницы/категории/подкатегории добавить сюда
        if (isHealthMain(routeName)) {
          return false;
        }

        return (
          isArticlePage(routeName) ||
          isPreviewPage(routeName) ||
          useHealthStore().isHealthArticle ||
          useHealthStore().isHealthPage
        );
      },
      // отображаем "правый сайдбар", если это "Главная/Архивы" ИЛИ "Внутренняя" с шаблоном "2/3 колонки"
      isRightSidebarVisible(): boolean {
        if (this.isHealthAboutProjectPage || this.isHealthExpertsPage) {
          return false;
        }

        return (
          this.isErrorPage ||
          !this.isSingleArticle ||
          !!(this.article?.template && ['two-column'].includes(this.article?.template))
        );
      },

      isErrorPage(): boolean {
        return Boolean(useError().value);
      },

      isHealthAboutProjectPage(): boolean {
        return useRoute().name === 'health-about-project';
      },

      isHealthExpertsPage(): boolean {
        return useRoute().name === 'health-experts';
      },

      isJumperPost(): boolean {
        return this.isSingleArticle && usePageStore().isJumperPost;
      },

      isShowFooter(): boolean {
        return !this.isJumperPost;
      },

      // Специальный раздел в вп "страницы" для статей, которые не попадают в ленту
      isArticleTypePage(): boolean {
        return this.isSingleArticle && usePageStore().isArticleTypePage;
      },

      isAdvertisingPost(): boolean {
        return this.isSingleArticle && usePageStore().isAdvertisingPost;
      },
      isPromoArticle(): boolean {
        return usePageStore().isPromo;
      },
      isAdsException(): boolean {
        return ADS_EXCEPTIONS.includes(this.article?.advertLabel ?? '');
      },
      routePath(): string {
        return useRoute().path;
      },
      throttledScroll() {
        return useThrottleFn(this.onScroll, 100);
      },
    },

    watch: {
      // https://youtrack.lifehacker.ru/issue/LH-1204
      // при смене роута скрываем контент на небольшое время,
      // тк некоторые блоки, которые появляются/исчезают на
      // новом или текущем роуте, могут ломать верстку
      routePath(): void {
        this.isRerender = true;
        setTimeout(() => {
          this.isRerender = false;
        }, 200);
        // сброс мета тегов
        this.metaSingleArticle.forEach((meta) => {
          meta.content = 'null';
        });
      },
    },

    mounted() {
      // console.info('🔥 this < mounted < layouts/default.vue', this);

      window.addEventListener('scroll', this.throttledScroll);
      focusVisibleInstance.focusVisibleInit();

      // * analytics pagescroll content
      percentageInit('.single-article__content');
      percentageInit();
    },

    beforeUnmount() {
      window.removeEventListener('scroll', this.throttledScroll);
    },

    methods: {
      onScroll(): void {
        const contentPercent = viewedContentPercent();
        const pagePercent = viewedPagePercent();

        if (contentPercent) {
          this.percentageSendAnalytics(
            getContentObject(),
            contentPercent,
            'Скролл материала',
            true,
          );
        }
        if (pagePercent) {
          this.percentageSendAnalytics(getPageObject(), pagePercent, 'Скролл страницы');
        }
      },
      percentageSendAnalytics(
        typeCategory: PercentageObject,
        newValue: number,
        eventYm: string,
        isContentScroll: boolean = false,
      ) {
        const { thresholds, key, category } = typeCategory;

        const viewedThresholds = Object.keys(thresholds)
          .map(Number)
          .filter((threshold) => threshold <= newValue && !thresholds[threshold]);

        viewedThresholds.forEach((threshold) => {
          thresholds[threshold] = true;

          const scrollEventParams = {
            [key]: {
              event: category,
              value: threshold.toString(),
            },
          };
          window.ym(YA_METRIKA_ID, 'params', scrollEventParams);

          this.$sendYandexMetrika({
            level1: eventYm,
            level4: threshold.toString(),
          });

          // Пока отключен глобальный сбор событий сноуплоу
          // TODO: Заменить на sendAnalyticsSnowPlow если он включен
          if (isContentScroll) {
            trackSelfDescribingEvent({
              event: {
                schema: 'iglu:dev.snowplow.simple/custom_data/jsonschema/1-0-0',
                data: {
                  postScroll: threshold.toString(),
                },
              },
            });
          }
        });
      },

      setMetaSingleArticleContent(hid: string, content: string): void {
        const target = this.metaSingleArticle.find((meta) => meta.hid === hid);
        if (target) {
          target.content = content;
        }
      },
    },
  });
</script>

<style lang="scss" src="./styles/default/index.scss" scoped></style>

<style lang="scss">
  .widgets-below-header {
    // margin-top: 16px;

    @include tablet {
      margin-top: 0;
    }
  }

  .below-comments-widgets__container {
    .zen-widget.widget {
      width: calc(100% + 16px);

      @include tablet {
        width: calc(100% + 24px);
      }
    }
  }

  .footer-widgets__container-1 {
    .zen-widget.widget {
      width: calc(100% + 16px);

      @include tablet {
        width: calc(100% + 24px);
      }
    }

    .single-article__post-content {
      .lightgrey-box {
        @include tablet {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .left-sidebar-and-other-content {
    &--rerender {
      opacity: 0;
    }
  }

  // Когда отображается брендирование
  // fix LH-1433
  .adfox-banner-background ~ #__nuxt .route-archive .left-sidebar-and-other-content {
    margin-top: 0;
  }

  .adfox-banner-background {
    display: none;

    @include big-tablet {
      display: block;

      // убираем костыль, который перебивал левые стили с adfox
      // > a[href^="https://ads.adfox.ru/200984/"]  {
      //   background-position: center top !important;
      //   background-repeat: unset !important;
      // }
    }

    @include print {
      display: none;
    }
  }

  a[href*='adfox'],
  div[id^='adfox_'] {
    @include print {
      display: none;
    }
  }
</style>
