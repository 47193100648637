<template lang="pug">
  .jumper-container__wrapper
    ClientOnly
      JumperMobile(
        v-if="type === 'mobile'"
        v-bind="jumperProps"
        @open-link="openLink"
      )
      JumperDesktop(
        v-else-if="type === 'desktop' || type === 'tablet'"
        v-bind="jumperProps"
        @open-link="openLink"
      )
</template>

<script lang="ts">
  import { timeout } from '~/utils';
  import PageScroll from '~/libs/PageScroll';
  import { usePageStore } from '~/store/pagesData';
  import JumperMobile from '~/components/jumper/JumperMobile.vue';
  import JumperDesktop from '~/components/jumper/JumperDesktop.vue';

  type Images = {
    desktop: string;
    tablet: string;
    mobile: string;
  };

  const DELAY_BEFORE_OPEN_LINK = 800;
  const TABLET_BREAKPOINT = 768;
  const DESKTOP_BREAPOINT = 1280;

  export default defineNuxtComponent({
    name: 'JumperContainer',

    components: {
      JumperMobile,
      JumperDesktop,
    },

    data: (): {
      type: 'mobile' | 'desktop' | 'tablet';
      prerenderLink: HTMLLinkElement | null;
    } => ({
      type: 'desktop',
      prerenderLink: null,
    }),

    computed: {
      jumperData(): IArticleMeta['jumperScroller'] | undefined {
        return usePageStore().currentArticle?.meta.jumperScroller;
      },

      linkText(): string {
        return this.jumperData?.name ?? '';
      },

      pitch(): string {
        return this.jumperData?.pitch ?? '';
      },

      url(): string {
        return this.jumperData?.url ?? '';
      },

      images(): Images | undefined {
        return this.jumperData?.images ?? undefined;
      },

      jumperProps(): {
        readonly imageUrl: string;
        readonly link: string;
        readonly linkText: string;
        readonly pitch: string;
      } {
        return {
          imageUrl: this.images?.[this.type] ?? '',
          linkText: this.linkText,
          pitch: this.pitch,
          link: this.url,
        };
      },
    },

    mounted() {
      window.scrollTo(0, 0);
      this.setType();
      this.prerenderLink = this.addPreRenderLink(this.url);
      window.addEventListener('resize', this.setType);
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.setType);
      this.prerenderLink?.remove();
    },

    methods: {
      setType() {
        if (window.innerWidth >= DESKTOP_BREAPOINT) {
          this.type = 'desktop';
        } else if (window.innerWidth >= TABLET_BREAKPOINT) {
          this.type = 'tablet';
        } else {
          this.type = 'mobile';
        }
      },

      addPreRenderLink(url: string): HTMLLinkElement {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'prerender';
        link.href = url;
        document.head.appendChild(link);
        return link;
      },

      // @param {boolean} isFromClick - Пришло ли событие с клика пользователя или ссылка открыта
      // из-за скролла
      async openLink(isFromClick: boolean): Promise<void> {
        PageScroll.disable();

        const eventName = isFromClick ? 'Клик_джампер скролл' : 'Переход_джампер скролл';

        this.$sendYandexMetrika({
          level1: eventName,
          level4: this.url,
        });

        await timeout(DELAY_BEFORE_OPEN_LINK);
        window.location.assign(this.url);
      },
    },
  });
</script>

<style scoped lang="scss">
  .jumper-container {
    &__wrapper {
      // fix LH-1236
      // для того чтобы перебить translate3d(0, 0, 1000px) в header
      // и z-index header
      transform: translate3d(0, 0, 1001px);
      z-index: 4;
      position: relative;
    }
  }
</style>
