<template lang="pug">
.promo__container.template-promo(
  :style="wrapperStyle"
  @click="openLink"
)
  BasePicture.promo__picture.promo__picture_top(
    :desktopUrl="images.topDesktop"
    :mobileUrl="images.topMobile"
    :alt="'Логотип'"
    :media="'(max-width: 767px)'"
  )

  .promo__content-wrapper(ref="promoContentWrapper")
    .promo__content
      slot

  BasePicture.promo__picture.promo__picture_bottom(
    :class="{'jumper-padding' : isJumperPost}"
    :desktopUrl="images.bottomDesktop"
    :mobileUrl="images.bottomMobile"
    :alt="'Логотип'"
    :media="'(max-width: 767px)'"
  )

  //- Джампер
  JumperContainer(v-if="isJumperPost")
</template>

<script lang="ts">
  import BasePicture from '~/components/BasePicture.vue';
  import JumperContainer from '~/containers/JumperContainer/JumperContainer.vue';
  import { usePageStore } from '~/store/pagesData';

  export default defineNuxtComponent({
    name: 'PromoContainer',

    components: {
      BasePicture,
      JumperContainer,
    },

    computed: {
      currentArticle() {
        return usePageStore().currentArticle;
      },
      promoData(): IArticleMeta['promo'] | undefined {
        return this.currentArticle?.meta.promo;
      },

      isJumperPost(): boolean {
        return usePageStore().isJumperPost;
      },

      backgroundColor(): string {
        return this.promoData?.bgColor ?? '';
      },

      link(): string {
        return this.promoData?.url ?? '';
      },

      wrapperStyle(): Record<string, string> {
        const wrapperStyle: Record<string, string> = {};

        if (this.backgroundColor) {
          wrapperStyle['background-color'] = this.backgroundColor;
        }

        if (this.currentArticle?.meta.mainColor) {
          wrapperStyle['--main-color'] = this.currentArticle?.meta.mainColor;
        }

        return wrapperStyle;
      },

      images(): {
        topDesktop: string | null;
        bottomDesktop: string | null;
        topMobile: string | null;
        bottomMobile: string | null;
      } {
        const images = this.promoData?.images;
        return {
          topDesktop: images?.topDesktop ?? '',
          topMobile: images?.topMobile ?? '',
          bottomDesktop: images?.bottomDesktop ?? '',
          bottomMobile: images?.bottomMobile ?? '',
        };
      },
    },

    methods: {
      openLink(event: Event): void {
        const promoContentWrapper = this.$refs.promoContentWrapper as HTMLElement;
        const composedPath = event.composedPath();

        // если пользователь кликнул внутри контентной области,
        // промо ссылку НЕ открываем
        if (composedPath.includes(promoContentWrapper)) {
          return;
        }

        // открываем промо ссылку
        this.link.length && window.open(this.link, '_blank');
      },
    },
  });
</script>

<style lang="scss">
  $backgroundColor: black;
  $contentBackgroundColor: white;

  .promo__container {
    background-color: $backgroundColor;
    cursor: pointer;
  }

  .promo__content-wrapper {
    $border: 1px solid #e7e7e7;

    cursor: default;
    background-color: #ffffff;
    padding-left: 0;
    padding-right: 0;

    @include tablet {
      margin: 0 auto;
      background: unset;
      max-width: 760px;
      padding-bottom: 0;
    }

    @include new-tablet {
      max-width: 960px;
    }

    @include desktop() {
      max-width: 1120px;
    }

    @include new-desktop-big {
      max-width: 1280px;
    }
  }

  .promo__content {
    width: 100%;
    margin: 0 auto;
    background-color: $contentBackgroundColor;
  }

  .promo__picture {
    position: relative;

    display: block;
    max-width: 100%;
    padding-bottom: 88px;

    overflow: hidden;

    @include tablet {
      padding-bottom: 240px;
    }

    &_bottom {
      padding-bottom: 240px;
    }

    &.jumper-padding {
      padding-bottom: 48px;
    }
  }

  .promo__picture .image {
    position: absolute;
    left: calc((100vw - 767px) / 2);

    width: 767px;
    height: 88px;
    max-width: unset;

    object-fit: cover;

    @include tablet {
      left: calc((100vw - 1920px) / 2);

      height: 240px;
      width: 1920px;
    }
  }

  .promo__picture_bottom .image {
    width: 767px;
    height: 240px;

    @include tablet {
      left: calc((100vw - 1920px) / 2);

      height: 240px;
      width: 1920px;
    }
  }
</style>
