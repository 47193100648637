<script setup lang="ts">
  import { usePageStore } from '~/store/pagesData';
  import PartnerBanner from '../components/PartnerBanner.vue';

  // OLD
  // const {
  //   bannerUrl: link,
  //   bannerImage: image,
  // } = this.$store.getters.currentArticle?.meta?.advert ?? { link: null, image: null };
  // const props: PartnerBannerProps = { link, image };

  // TODO:  Check if advert properties exists in api or wrong type
  const advert = usePageStore().currentArticle?.meta.advert;
</script>

<template>
  <PartnerBanner
    :link="advert?.bannerUrl"
    :image="advert?.bannerImage"
  />
</template>
