import type { PropType } from 'vue';

export const jumperProps = {
  link: {
    type: String as PropType<string>,
    required: true,
  },

  linkText: {
    type: String as PropType<string>,
    required: true,
  },

  imageUrl: {
    type: String as PropType<string>,
    required: true,
  },

  pitch: {
    type: String as PropType<string>,
    required: true,
  },
};
