// полифил для focus-visible
// стилизовать эффекты focus-visible можно по атрибуту, который передаётся при создании экземпляра класса
// в данном случае стилизация происходит в main.css по аттрибуту data-focus-visible

class FocusVisible {
  public attribute: string;
  public attributeValue?: string | undefined;
  constructor(attribute: string, attributeValue?: string) {
    this.attribute = attribute;
    this.attributeValue = attributeValue;
  }

  private checkKey(event: KeyboardEvent): boolean {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      return true;
    }
    return false;
  }

  private focus(): void {
    const target = document.querySelector(':focus');
    if (target) {
      target.setAttribute(this.attribute, this.attributeValue || '');
    }
  }

  private triggerFocusVisible(event: KeyboardEvent): void {
    if (this.checkKey(event)) {
      this.unFocus();
      setTimeout(this.focus.bind(this));
    }
  }

  private unFocus(): void {
    const prevTarget = document.querySelector('[' + this.attribute + ']');
    if (prevTarget) {
      prevTarget.removeAttribute(this.attribute);
    }
  }

  public focusVisibleInit(): void {
    document.addEventListener('keydown', this.triggerFocusVisible.bind(this));
    document.addEventListener('click', this.unFocus.bind(this));
  }

  public focusVisibleDestroy(): void {
    document.removeEventListener('keydown', this.triggerFocusVisible);
    document.removeEventListener('click', this.unFocus);
  }
}

const focusVisibleInstance = new FocusVisible('data-focus-visible');

export default focusVisibleInstance;
