<template lang="pug">
  .container(
    ref="section"
  )
    .wrapper(
      ref="footerContainer"
    )
      .header
        .header__text Листая дальше, вы перейдете на страницу &#32
          a.header__link(
            @click="() => openLink(true)"
            :href="link"
            :alt="`Открыть ссылку на сторонний сайт ${linkText}`"
          ) {{ linkText }}
        .header__pitch {{ pitch }}
      .content
        .content__overlay(
          ref="overlay"
        )
        .content__image-container(
          ref="imageContainer"
          :style="{ 'transform': imageContainerTransform }"
        )
          .content__image-wrapper
            a.content__image-link(
              @click="() => openLink(true)"
              :href="link"
              aria-label="Открыть ссылку на сторонний сайт"
            )
              .content__header
              .content__image(
                ref="image"
                :style="{ 'background-image': `url(${imageUrl})` }"
              )
</template>

<script lang="ts">
  import { detect } from 'detect-browser';

  import { timeout } from '~/utils';
  import { jumperProps as props } from '~/components/jumper/props';

  const DELAY_BEFORE_INIT = 2000;
  const CLASS_NAME_CONTENT_CONTAINER = 'content-container__for-jumper-desktop';
  const CLASS_NAME_FOR_FIXED = 'set-position-fixed';

  const isSafari = (): boolean => {
    const browser = detect();
    return browser?.name === 'safari';
  };

  type Func = () => void;

  export default defineNuxtComponent({
    name: 'JumperDesktop',

    props,

    data() {
      return {
        startTranslateY: 0 as number,
        imageContainerTransform: '' as string,
        contentContainerTop: 0 as number,

        handleScrollInSafari: null as null | Func,
      };
    },

    async mounted() {
      const contentContainer: HTMLElement | undefined = this.getContentContainer();
      if (contentContainer) {
        await timeout(DELAY_BEFORE_INIT);
        this.setStartVariables();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.setStartVariables);

        // fix LH-1210
        if (isSafari()) {
          const lastWidgetInSidebar: HTMLElement | null = document.querySelector(
            '.other-content__sidebar-widget:last-child',
          );
          this.handleScrollInSafari = lastWidgetInSidebar
            ? useThrottleFn(
                () =>
                  this.updateLastWidgetInSidebar(
                    lastWidgetInSidebar,
                    lastWidgetInSidebar.previousElementSibling,
                  ),
                100,
              )
            : null;
          if (this.handleScrollInSafari) {
            window.addEventListener('scroll', this.handleScrollInSafari);
          }
        }
      }
    },

    beforeUnmount(): void {
      window.removeEventListener('resize', this.setStartVariables);
      window.removeEventListener('scroll', this.handleScroll);
      this.removeStickyClassAndTopPositionForContentContainer();

      if (this.handleScrollInSafari) {
        window.removeEventListener('scroll', this.handleScrollInSafari);

        const lastWidgetInSidebar: HTMLElement | null = document.querySelector(
          '.other-content__sidebar-widget:last-child',
        );
        if (lastWidgetInSidebar) {
          lastWidgetInSidebar.classList.remove(CLASS_NAME_FOR_FIXED);
        }
      }
    },

    methods: {
      // фиксирование последнего элемента в сайдбаре тк sticky дергается LH-1210
      updateLastWidgetInSidebar(element: Element, previousElement: Element | null): void {
        // значение top у фиксированного элемента сайдбара и меню в карточках
        const TOP = 72;
        // высота хеадера
        const HEADER_HEIGHT = 48;
        // нужно ли фиксировать
        const isFixedNeeded =
          (previousElement && HEADER_HEIGHT >= previousElement.getBoundingClientRect().bottom) ||
          (!previousElement && element.getBoundingClientRect().top >= TOP);
        if (isFixedNeeded) {
          element.classList.add(CLASS_NAME_FOR_FIXED);
        } else {
          element.classList.remove(CLASS_NAME_FOR_FIXED);
        }
      },

      setStartVariables(): void {
        const contentContainer: HTMLElement | undefined = this.getContentContainer();
        if (!contentContainer) {
          return;
        }
        this.addStickyClassAndTopPositionForContentContainer(contentContainer);
      },

      getContentContainer(): HTMLElement | undefined {
        const container: unknown = document.querySelector(`.${CLASS_NAME_CONTENT_CONTAINER}`);
        if (!(container instanceof HTMLElement)) {
          return undefined;
        }
        return container;
      },

      addStickyClassAndTopPositionForContentContainer(container: HTMLElement) {
        // container.style.position = 'sticky';

        const offset = this.calculateStickyTopOffset(container);
        const top = offset - 500;

        // container.style.top = -top + 'px';
        this.contentContainerTop = top;
        this.contentContainerTop = offset - 500;
      },

      removeStickyClassAndTopPositionForContentContainer(): void {
        const container: HTMLElement | undefined = this.getContentContainer();
        container?.style?.removeProperty('position');
        container?.style?.removeProperty('top');
      },

      calcStartScale(): number {
        return 1248 / window.innerWidth;
      },

      calculateStickyTopOffset(elem: HTMLElement): number {
        return elem.getBoundingClientRect().height;
      },

      handleScroll(): undefined {
        if (window.scrollY <= this.contentContainerTop - 500) {
          return undefined;
        }
        const { section, image } = this.$refs;
        if (!(section instanceof HTMLElement) || !(image instanceof HTMLElement)) {
          return undefined;
        }
        const imageOffsetTop = image.getBoundingClientRect().top;
        if (imageOffsetTop < 70) {
          this.openLink();
          return undefined;
        }

        const sectionOffsetTop = section.getBoundingClientRect().top;
        if (sectionOffsetTop >= window.innerHeight / 1.5) {
          this.setOverlayOpacity(0);
          return undefined;
        }

        const scale = 1 - sectionOffsetTop / window.innerHeight;

        this.setOverlayOpacity(scale - 0.35);
      },

      setOverlayOpacity(opacity: number): void {
        const { overlay } = this.$refs;
        if (overlay instanceof HTMLElement) {
          overlay.style.opacity = `${opacity}`;
          overlay.style.pointerEvents = opacity ? 'all' : 'none';
        }
      },

      // @param {boolean} isFromClick - Пришло ли событие с клика пользователя или ссылка открыта
      // из-за скролла
      openLink(isFromClick = false): void {
        document.body.style.transition = 'opacity 0.7s ease-in';
        document.body.style.opacity = '0.1';

        this.$emit('open-link', isFromClick);
      },
    },
  });
</script>

<style lang="scss" scoped>
  @use './_variables.scss' as jumperVars;

  .wrapper {
    position: relative;
    width: 100%;
    background-color: #ffffff;

    // fix LH-1206
    // для того чтобы перебить translate3d(0, 0, 1000px) в header
    transform: translate3d(0, 0, 1001px);
  }

  .container {
    width: 100%;
  }

  .header {
    position: sticky;
    top: 0;

    width: 100%;
    padding: 40px 28px 0px;
    text-align: center;
    background-color: #ffffff;
    z-index: 1;
  }

  .header__link {
    display: inline-block;
    text-decoration: none;
    color: jumperVars.$linkColor;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom-color: jumperVars.$linkColor;
    }
  }

  .header__text {
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
  }

  .header__pitch {
    margin-top: 8px;

    font-family: $secondFont;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
  }

  .content {
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #ffffff;
  }

  .content__overlay {
    position: fixed;
    top: -80%;
    left: 0;

    width: 100%;
    height: 100vh;

    background-color: jumperVars.$overlayBackgroundColor;
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
  }

  .content__image-container {
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: jumperVars.$imageContainerBackgroundColor;
    overflow: hidden;
  }

  .content__image-wrapper {
    width: 100%;
    height: 100%;
  }

  .content__image-link {
    width: 100%;
    height: 100%;

    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    cursor: pointer;
    overflow: hidden;
    user-select: none;
    color: jumperVars.$imageLinkColor;
    text-decoration: none;
  }

  .content__image {
    width: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .content__header {
    position: relative;

    height: 25px;
    border-bottom: 1px solid jumperVars.$contentHeaderBorderColor;
    margin-top: 40px;
    box-shadow: 0px 5px 37px 0px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #ffffff;
    font-weight: 500;
    flex-shrink: 0;
  }
</style>
